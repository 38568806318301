import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = () => (
  <footer className="bg-white border-t-4 border-teal mt-24 text-teal">
    <div className="max-w-5xl py-6 mx-auto">
      <p class="max-w-5xl">
        © {new Date().getFullYear()} Stefanie Fiore, designed + built by me
        using{" "}
        <a
          href="https://www.gatsbyjs.org"
          target="_blank"
          class="underline hover:text-tomat"
        >
          Gatsby
        </a>
      </p>
    </div>
  </footer>
)

export default Footer
